//
// Topbar
//

$kt-topbar-icon-border-color: rgba(#fff, 0.2);
$kt-topbar-icon-border-hover-color: rgba(#fff, 0.5);
$kt-topbar-icon-color: #fff;

$kt-topbar-minimize-icon-border-color: #e7e8f4;
$kt-topbar-minimize-icon-border-hover-color: #d7d8e9;

$kt-topbar-icon-size: 42px;
$kt-topbar-minimize-icon-size: 34px;
$kt-topbar-mobile-icon-size: 34px;

// General mode
.kt-header__topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  align-content: flex-end;
  z-index: 1;

  .kt-header__topbar-item {
    display: flex;
    align-items: stretch;
    margin: 0 0.25rem;

    .kt-header__topbar-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;

      .kt-badge {
        height: $kt-topbar-icon-size;
        width: $kt-topbar-icon-size;
        background-clip: padding-box; /* Firefox 4+, Opera, for IE9+, Chrome */
        margin-left: 0.5px;
        background: #050037;
        color: #fff;
        font-size: 1.6rem;
      }

      .kt-header__topbar-icon {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        height: $kt-topbar-icon-size;
        width: $kt-topbar-icon-size;
        cursor: pointer;
        background: transparent;
        @include kt-transition();

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }

        img {
        }

        i {
          line-height: 0;
          font-size: 1.3rem;
          color: #82829c;
        }
      }
    }

    &:hover,
    &.show {
      .kt-header__topbar-icon {
        @include kt-transition();
        background-color: lighten(#1f1e2e, 75%);

        i {
          color: kt-brand-color(inverse) !important;
        }
      }
    }

    // Languages
    &.kt-header__topbar-item--langs {
      .kt-header__topbar-icon {
        img {
          border-radius: 0.2rem;
          width: 22px;
          height: 22px;
        }
      }

      .dropdown-menu {
        .kt-nav__link-icon {
          padding-right: 10px;

          img {
            border-radius: 50%;
            width: 18px;
          }
        }
      }
    }

    // User profile
    &.kt-header__topbar-item--user {
      //padding: 0 0.4rem;

      .kt-header__topbar-welcome {
        display: flex;
        align-self: center;
        padding: 0 0.55rem 0 0;
        margin-left: 24px;
        font-weight: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #494c5e;
      }

      .kt-header__topbar-username {
        display: flex;
        align-self: center;
        margin-right: 8px;

        font-weight: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #090c1d;
      }

      .kt-header__topbar-wrapper {
        img {
          align-self: center;
          height: $kt-topbar-icon-size;
          border-radius: 50%;
        }
      }

      .kt-user-card {
        .kt-user-card__pic {
          img {
            border-radius: 6px;
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// Tablet and mobile mode(1024px and below)
@include kt-tablet-and-mobile() {
  .kt-header__topbar {
    padding: 0 kt-get($kt-page-padding, mobile);
    background-color: #fff;
    transition: all 0.3s ease;
    margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
    height: kt-get($kt-header-config, topbar, height, mobile);
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid transparent;

    // Fixed mobile header
    .kt-header-mobile--fixed & {
      position: fixed;
      z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
    }

    // Topbar shown
    .kt-header__topbar--mobile-on & {
      margin-top: 0;
      transition: all 0.3s ease;
      box-shadow: kt-get(
        $kt-header-config,
        base,
        mobile,
        self,
        fixed,
        box-shadow
      );
      border-top: 1px solid kt-base-color(grey, 2);
    }

    // Topbar item
    .kt-header__topbar-item {
      margin: 0 0.25rem;
      .kt-header__topbar-wrapper {
        display: flex;
        align-items: center;

        .kt-badge {
          max-height: $kt-topbar-mobile-icon-size;
          max-width: $kt-topbar-mobile-icon-size;
          margin-left: -2px;
        }

        .kt-header__topbar-icon {
          max-height: $kt-topbar-mobile-icon-size;
          max-width: $kt-topbar-mobile-icon-size;
          border: 0;

          i {
            font-size: 1.3rem;
            color: kt-base-color(label, 2);
          }
        }
      }

      &:hover,
      &.show {
        .kt-header__topbar-icon {
          @include kt-transition();
          background-color: kt-base-color(grey, 1);

          i {
            color: kt-brand-color();
          }
        }
      }

      // Languages
      &.kt-header__topbar-item--langs {
        margin: 0 1.2rem 0 0.7rem;
      }

      &.kt-header__topbar-item--user {
        padding: 0 0 0 0.25rem;
        margin: 0;

        .kt-header__topbar-welcome {
          color: kt-base-color(label, 2);
          padding: 0 0.35rem 0 0;
          font-size: 0.9rem;
        }

        .kt-header__topbar-username {
          color: kt-base-color(label, 3);
          padding: 0 0.55rem 0 0;
          font-size: 0.9rem;
        }

        .kt-header__topbar-wrapper {
          img {
            max-height: $kt-topbar-mobile-icon-size;
            margin: 0 0.5rem 0 0.2rem;
          }
        }
      }

      // Search
      &.kt-header__topbar-item--search {
        .kt-input-icon {
          .kt-input-icon__icon {
            i {
              color: kt-base-color(label, 2);
            }
          }

          .form-control {
            height: $kt-topbar-mobile-icon-size;
            border: 1px solid $kt-topbar-minimize-icon-border-color;
            color: kt-base-color(label, 3);
            border: 0;

            @include kt-input-placeholder(kt-base-color(label, 2));

            &:focus {
              border: 0;
            }
          }
        }
      }

      &:last-child {
        padding-right: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
